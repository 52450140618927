<template>
  <div
  	class="header_section"
  	:style="getBackgroundImage"
  >
	  <div class="top_section">
			<div class="container">
			  <nav class="navbar navbar-expand-lg navbar-light bg-light">
					<div class="logo">
						<a @click="goTo('home')">
							<img :src="require('@/assets/images/purinvest-logo.png')">
						</a>
					</div>
					<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
					</button>
					<div
						v-if="authInfoLoaded"
						class="collapse navbar-collapse"
						id="navbarSupportedContent"
					>
						<ul v-if="$route.name == 'home'" class="navbar-nav ml-auto mr-auto">
					     <li class="nav-item">
					        <a class="nav-link" href="#about">О нас</a>
					     </li>
					     <li class="nav-item">
					        <a class="nav-link" href="#analytics">Аналитика</a>
					     </li>
					     <li class="nav-item">
					        <a class="nav-link" href="#faq">Частые вопросы</a>
					     </li>
					     <li class="nav-item">
					        <a class="nav-link" href="#contacts">Обратная связь</a>
					     </li>
						</ul>
					  <ul v-if="user" class="navbar-nav ml-auto mr-auto">
					     <li v-if="$route.name != 'profile'" class="nav-item">
					        <a class="nav-link" @click="goTo('profile')">Личный кабинет</a>
					     </li>
					     <li class="nav-item">
					        <a class="nav-link" @click="logout">Выйти</a>
					     </li>
					  </ul>
					  <ul v-else class="navbar-nav ml-auto mr-auto">
					     <li class="nav-item">
					        <a class="nav-link" @click="openAuthDialog('register')">Регистрация</a>
					     </li>
					     <li class="nav-item">
					        <a class="nav-link" @click="openAuthDialog('login')">Войти</a>
					     </li>
					  </ul>
					  <!-- <form class="form-inline my-2 my-lg-0">
					     <div class="login_bt">
					        <ul>
					           <li><a href="#"><i class="fa fa-search" aria-hidden="true"></i></a></li>
					        </ul>
					     </div>
					  </form> -->
					</div>
			  </nav>
			</div>
		</div>
	  <div
	  	v-if="isHomePage"
	  	class="banner_section layout_padding"
	  >
	     <div id="my_slider" class="carousel slide" data-ride="carousel">
	        <div class="carousel-inner">
	           <div class="carousel-item active">
	              <div class="container">
	                 <div class="row">
	                    <div class="col-sm-12">
	                       <div class="banner_taital_main">
	                          <!-- <h1 class="banner_taital">Corporate <br>Business<br> Your Marketing</h1> -->
	                          <h1 class="banner_taital">АО &laquo;Пуринвест&raquo;</h1>
	                          <h2 class="banner_taital_2">Ваш надёжный брокер на финансовом рынке</h2>
	                           <!-- <p class="banner_text">It is a long established fact that a reader will be distracted by the readable content of a page when</p> -->
	                          <!-- <div class="btn_main">
	                             <div class="started_text"><a href="about.html">About Us</a></div>
	                             <div class="started_text active"><a href="contact.html">Contact Us</a></div>
	                          </div> -->
	                       </div>
	                    </div>
	                 </div>
	              </div>
	           </div>
	           <!-- <div class="carousel-item">
	              <div class="container">
	                 <div class="row">
	                     <div class="col-sm-12">
	                       <div class="banner_taital_main">
	                           <h1 class="banner_taital">Corporate <br>Business<br> Your Marketing</h1>
	                           <p class="banner_text">It is a long established fact that a reader will be distracted by the readable content of a page when</p>
	                           <div class="btn_main">
	                              <div class="started_text"><a href="about.html">About Us</a></div>
	                              <div class="started_text active"><a href="contact.html">Contact Us</a></div>
	                           </div>
	                        </div>
	                     </div>
	                  </div>
	               </div>
	            </div>
	            <div class="carousel-item">
	              <div class="container">
	                 <div class="row">
	                    <div class="col-sm-12">
	                       <div class="banner_taital_main">
	                          <h1 class="banner_taital">Corporate <br>Business<br> Your Marketing</h1>
	                          <p class="banner_text">It is a long established fact that a reader will be distracted by the readable content of a page when</p>
	                          <div class="btn_main">
	                             <div class="started_text"><a href="about.html">About Us</a></div>
	                             <div class="started_text active"><a href="contact.html">Contact Us</a></div>
	                          </div>
	                       </div>
	                    </div>
	                 </div>
	              </div>
	           </div> -->
	        </div>
	        <!-- <a class="carousel-control-prev" href="#my_slider" role="button" data-slide="prev">
	           <i class="fa fa-angle-left"></i>
	        </a>
	        <a class="carousel-control-next" href="#my_slider" role="button" data-slide="next">
	           <i class="fa fa-angle-right"></i>
	        </a> -->
	     </div>
	  </div>
  </div>
  <AuthDialog :dialog="authDialog" />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AuthDialog from '@/components/Dialog/AuthDialog'

export default {
  components: { AuthDialog },
  data () {
    return {
      authDialog: {
        visible: false,
        action: null
      },
      authInfoLoaded: false
    }
  },
	computed: {
		...mapState('auth', ['user']),
		isHomePage () {
			return this.$route.name == 'home'
		},
		getBackgroundImage () {
			if (this.isHomePage) {
				return {
					'background-image': `url(${require('@/assets/images/preview.png')})`
				}
			}
			return {}
		},
	},
	async mounted () {
		await this.$store.dispatch('auth/getUser')
		console.log(this.user)
		this.authInfoLoaded = true
	},
  methods: {
		...mapActions('auth', ['logoutUser']),
  	openAuthDialog (action) {
  		this.authDialog.action = action
  		this.authDialog.visible = true
  	},
  	goTo (route) {
  		this.$router.push({ name: route })
  	}, 
  	async logout () {
  		let loggedOut = await this.logoutUser()
  		if (loggedOut) {
  			this.goTo('home')
  		}
  	}
  }
}
</script>
