<template>
  <div class="analytics_section layout_padding" id="analytics">
    <div class="container">
      <div class="analytics_main">
        <div class="row">
          <div class="col-md-12">
            <h1 class="analytics_taital">Аналитика <span class="border_0"></span></h1>
          </div>
        </div>
        <div class="row">
          <div
						v-for="(item, index) of analytics"
						:key="index"
						class="col-md-4"
          >
            <h3 class="selection_text">{{ item.title }}</h3>
            <p class="many_text">{{ item.text }}</p>
        	</div>
      	</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	computed: {
		...mapState('info', ['analytics']),
	},
	mounted () {
		this.$store.dispatch('info/getAnalytics')
	}
}
</script>
