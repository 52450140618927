import api from '@/services/api'
import { useCookies } from 'vue3-cookies'
import { io } from 'socket.io-client'
import { WEBSOCKET_URL } from '@/constants'

export const namespaced = true

export const state = {
  token: null,
  user: null,
  socket: null,
  chatMessages: [],
  unreadMessagesCount: 0,
  apiError: null,
  validationErrors: {},
}

export const mutations = {
  setToken (state, token) {
    state.token = token
  },
  setUser(state, user) {
    state.user = user
  },
  setSocket(state, socket) {
    state.socket = socket
  },
  setChatMessages(state, chatMessages) {
    state.chatMessages = chatMessages
  },
  setUnreadMessages(state, unreadMessagesCount) {
    state.unreadMessagesCount = unreadMessagesCount
  },
  addChatMessage(state, message) {
    state.chatMessages.push(message)
  },
  updateUnreadMessages(state) {
    state.unreadMessagesCount++
  },
  setError(state, error) {
    state.apiError = error
  },
  setErrors(state, errors) {
    state.validationErrors = errors
  },
}

export const actions = {
  getToken ({ commit }) {
    const { cookies } = useCookies()
    const token = cookies.get("access_token")
    commit('setToken', token)
  },
  saveToken ({ commit }, token) {
    const { cookies } = useCookies()
    cookies.set('access_token', token)
    commit('setToken', token)
  },
  deleteToken ({ commit }) {
    const { cookies } = useCookies()
    cookies.remove('access_token')
    commit('setToken', null)
  },
  initSocket({ commit }) {
    if (state.user && state.token) {
      if (!state.socket) {
        const socket = io(WEBSOCKET_URL, {
          extraHeaders: {
            Authorization: `Bearer ${state.token}`,
            'Content-type': 'application/json'      
          }
        })

        socket.on('connect', () => {
          socket.emit('join-customer-room', {
            room: `customer-room-${state.user.id}`
          })
        })

        socket.on('disconnect', () => {

        })

        socket.on('message:sent', (response) => {
          if (response.success) {
            commit('addChatMessage', response.message)
          }
        })

        socket.on('message:new', (response) => {
          commit('addChatMessage', response.message)
          commit('updateUnreadMessages')
        })

        commit('setSocket', socket)
      }
    }
  },
  destroySocket({ commit }) {
    if (state.socket) {
      state.socket.disconnect()
      commit('setSocket', null)
    }
  },
  async getUser ({ commit, dispatch }) {
    dispatch('getToken')
    const response = await api.get('/auth/user', {
      headers: {
        Authorization: `Bearer ${state.token}`,
        "Content-type": "application/json"
      }})
    // console.log(response)
    if (response.data.success) {
      commit('setUser', response.data.user)
      dispatch('initSocket')
    }
    else {
      console.log(response.data.error)
      commit('setUser', null)
    }
  },
  async loginUser ({ commit, dispatch }, payload) {
    commit('setError', null)
    commit('setErrors', {})
    try {
      const response = await api.post('/auth/login', payload)
        .catch((error) => {
          console.log(error)
          commit('setError', 'Ошибка авторизации. Попробуйте позже')
        })
      // console.log(response.data)
      if (response.data.success) {
        dispatch('saveToken', response.data.access_token)
        commit('setUser', response.data.user)
        dispatch('initSocket')
        return true
      }
      else {
        console.log(response.data.error)
        commit('setError', response.data.error)
        return false
      }
    } catch (error) {
      console.error(error)
      commit('setError', 'Ошибка авторизации. Попробуйте позже')
      return false
    }
  },
  async registerUser ({ commit, dispatch }, payload) {
    commit('setError', null)
    commit('setErrors', {})
    try {
      const response = await api.post('/auth/register', payload)
      // console.log(response)
      if (response.data.success) {
        dispatch('saveToken', response.data.access_token)
        commit('setUser', response.data.user)
        dispatch('initSocket')
        return true
      }
      else {
        console.log(response.data.error)
        commit('setError', response.data.error)
        return false
      }
    } catch (error) {
      console.error(error)
      commit('setError', 'Ошибка регистрации. Попробуйте позже')
      return false
    }
  },
  async saveData ({ commit, dispatch }, payload) {
    commit('setError', null)
    commit('setErrors', {})
    dispatch('getToken')
    try {
      const response = await api.post('/auth/save/data', payload, {
        headers: {
          Authorization: `Bearer ${state.token}`,
          "Content-type": "application/json"
        }
      })
      // console.log(response)
      if (response.data.success) {
        // dispatch('saveToken', response.data.access_token)
        // commit('setUser', response.data.user)
        return true
      }
      else {
        console.log(response.data.error)
        commit('setError', response.data.error)
        return false
      }
    } catch (error) {
      console.error(error)
      commit('setError', 'Ошибка сохранения данных. Попробуйте позже')
      return false
    }
  },
  async saveDocuments ({ commit, dispatch }, payload) {
    commit('setError', null)
    commit('setErrors', {})
    dispatch('getToken')
    try {
      const response = await api.post('/auth/save/documents', payload, {
        headers: {
          Authorization: `Bearer ${state.token}`,
          "Content-type": "multipart/form-data"
        }
      })
      // console.log(response)
      if (response.data.success) {
        // dispatch('saveToken', response.data.access_token)
        // commit('setUser', response.data.user)
        return true
      }
      else {
        console.log(response.data.error)
        commit('setError', response.data.error)
        return false
      }
    } catch (error) {
      console.error(error)
      commit('setError', 'Ошибка сохранения документов. Попробуйте позже')
      return false
    }
  },
  async addWithdraw ({ commit, dispatch }, payload) {
    commit('setError', null)
    commit('setErrors', {})
    dispatch('getToken')
    try {
      const response = await api.post('/auth/withdraw', payload, {
        headers: {
          Authorization: `Bearer ${state.token}`,
          "Content-type": "application/json"
        }
      })
      // console.log(response)
      if (response.data.success) {
        // dispatch('saveToken', response.data.access_token)
        // commit('setUser', response.data.user)
        return true
      }
      else {
        console.log(response.data.error)
        commit('setError', response.data.error)
        return false
      }
    } catch (error) {
      console.error(error)
      commit('setError', 'Ошибка создания заявки на вывод. Попробуйте позже')
      return false
    }
  },
  async sendChatMessage ({ commit }, payload) {
    commit('setError', null)
    commit('setErrors', {})
    try {
      if (state.socket) {
        state.socket.emit('message:customer', payload)
      }
      else {
        commit('setError', 'Ошибка Websocket подключения. Попробуйте позже')
      }
    } catch (error) {
      console.error(error)
      commit('setError', 'Ошибка отправки сообщения. Попробуйте позже')
    }
  },
  async getChatMessages ({ commit }) {
    commit('setError', null)
    commit('setErrors', {})
    try {
      const response = await api.get('/chat/messages', {
        headers: {
          Authorization: `Bearer ${state.token}`,
          "Content-type": "application/json"
        }
      })
      console.log(response)
      if (response.data.success) {
        commit('setChatMessages', response.data.messages)
        commit('setUnreadMessages', response.data.messages
          .filter(
            message => message.direction == 'outbox' && message.read == 0
          ).length
        )
        return response.data.messages
      }
      else {
        console.log(response.data.error)
        commit('setError', response.data.error)
        return []
      }
    } catch (error) {
      console.error(error)
      commit('setError', 'Ошибка получения сообщений. Попробуйте позже')
    }
  },
  async setReadMessages ({ commit }) {
    commit('setError', null)
    commit('setErrors', {})
    try {
      const response = await api.patch('/chat/set-read', {}, {
        headers: {
          Authorization: `Bearer ${state.token}`,
          "Content-type": "application/json"
        }
      })
      console.log(response)
      if (response.data.success) {
        commit('setUnreadMessages', 0)
        return true
      }
      else {
        console.log(response.data.error)
        commit('setError', response.data.error)
        return false
      }
    } catch (error) {
      console.error(error)
      commit('setError', error.toString())
    }
  },
  async forgotPassword ({ commit }, payload) {
    commit('setError', null)
    const response = await api.post('/password/forgot', payload)
      .catch((error) => {
        console.log(error)
        commit('setError', 'Ошибка отправки запроса. Попробуйте позже')
      })
    // console.log(response.data)
    if (response.data.success) {
      return true
    }
    else {
      console.log(response.data.error)
      commit('setError', response.data.error)
    }
    return false
  },
  async checkResetPassword (context, resetToken) {
    const response = await api.get('/password/check/' + resetToken)
      .catch((error) => {
        console.log(error)
      })
    return response.data.existToken ? true : false
  },
  async resetPassword ({ commit }, payload) {
    commit('setError', null)
    if (!payload.token) {
      commit('setError', 'Ошибка. Не указан ключ')
      return
    }
    const response = await api.post('/password/change', payload)
      .catch((error) => {
        console.log(error)
        commit('setError', 'Ошибка восстановления пароля. Попробуйте позже')
      })
    // console.log(response)
    if (response.data.success) {
      return true
    }
    else {
      console.log(response.data.error)
      commit('setError', response.data.error)
    }
    return false
  },
  async logoutUser ({ commit, dispatch }) {
    dispatch('getToken')
    const response = await api.post('/auth/logout', {}, {
      headers: {
        Authorization: `Bearer ${state.token}`,
        "Content-type": "application/json"
      }})
      .catch((error) => {
        console.log(error)
      })
    // console.log(response.data)
    if (response.data.success) {
      dispatch('deleteToken')
      commit('setUser', null)
      dispatch('destroySocket')
      return true
    }
    return false
  },
}

export const getters = {
  authUser: (state) => {
    return state.user
  },
  error: (state) => {
    return state.apiError
  },
  loggedIn: (state) => {
    return !!state.user
  }
}
