<template>
  <!-- investments section start -->
  <div class="investment_section">
     <div class="container">
        <div class="row">
           <div class="col-md-12">
              <h1 class="investment_taital">Инвестиционные портфели</h1>
           </div>
		        <div class="col-md-12">
		           <!-- <p class="about_text_1">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words </p> -->
		           <p class="about_text_1">
		           	Несколько счетов данного типа соединены воедино с целью создания страховочной инвестиции.<br>
		           	Каждый предыдущий портфель перекрывается более высоким процентом с целью получения среднестатистического процента свыше 7% в разрезе одного месяца.
		           </p>
		           <!-- <div class="readmore_bt"><a href="#">Read More</a></div> -->
		        </div>
        <!-- </div> -->
        <!-- <div class="investment_section_2"> -->
           <!-- <div class="row"> -->
              <div class="col-md-12">
                 <!-- <div class="blog_img">
                    <div class="video_bt">
                       <div class="play_icon"><img src="images/play-icon.png"></div>
                    </div>
                 </div> -->
                <img
                 	class="investment_img" 
                 	:src="require('@/assets/images/purinvest-investment.jpg')"
                >
              </div>
           </div>
        <!-- </div> -->
     </div>
  </div>
  <!-- investments section end -->
</template>