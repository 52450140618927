<template>
  <div class="contact_section layout_padding" id="contacts">
     <div class="container">
        <div class="row">
           <div class="col-sm-12">
              <h1 class="contact_taital">Остались Вопросы? <span class="border_0"></span></h1>
           </div>
        </div>
        <div class="contact_section_2">
           <div class="row">
              <div class="col-md-6">
                 <form action="">
                      <div class="mail_section_1">
                        <div class="relative">
                          <input
                            v-model="v$.feedback.name.$model"
                            type="text"
                            class="profile_field"
                            placeholder="Имя"
                          >
                          <div v-if="validationErrors.name" class="profile_error absolute">
                            {{ validationErrors.name }}
                          </div>
                        </div>
                        <div class="relative">
                          <input
                            v-model="v$.feedback.email.$model"
                            type="email"
                            class="profile_field"
                            placeholder="E-mail"
                          >
                          <div v-if="validationErrors.email" class="profile_error absolute">
                            {{ validationErrors.email }}
                          </div>
                        </div>
                        <div class="relative">
                          <input
                            v-model="v$.feedback.phone.$model"
                            type="text"
                            class="profile_field"
                            placeholder="Телефон"
                            v-maska
                            data-maska="['+7 (###) ###-##-##']"
                            @maska="onMaska"
                          >
                          <div v-if="validationErrors.phone" class="profile_error absolute">
                            {{ validationErrors.phone }}
                          </div>
                        </div>
                        <div class="relative">
                          <textarea
                            v-model="v$.feedback.message.$model"
                            class="message-bt"
                            placeholder="Сообщение"
                            rows="5"
                          ></textarea>
                          <div v-if="validationErrors.message" class="profile_error absolute">
                            {{ validationErrors.message }}
                          </div>
                        </div>
                        <button type="button" class="send_bt" @click="onSubmit">Отправить</button>
                      </div>
                 </form>
              </div>
              <div class="col-md-6">
                <p class="lorem_text">
                  Брокер осуществляет деятельность на основании лицензии профессионального участника рынка ценных бумаг на осуществление брокерской деятельности<br>
                  ИНН 8911000962;<br>
                  КПП 772201001;<br>
                  ОГРН 1028900857490;<br>
                  Дата присвоения Центральным Банком РФ: 28.08.2002. (Без ограничения
                  срока действия)
                </p>
                <p class="lorem_text document_links">
                  <a 
                    v-for ="(doc, index) of documents"
                    :key="index"
                    :href="`./documents/${doc.file}`"
                    target="_blank" 
                    class="document_link" 
                  >
                    {{ doc.title }}
                  </a>
                </p>
                <p class="lorem_text">
                  НАЙТИ НАС<br>
                  111020, г.Москва, ул.Боровая, д.3 стр.22
                </p>
              </div>
           </div>
        </div>
     </div>
  </div>
  <v-dialog
    v-model="infoDialog.visible"
    width="auto"
  >
    <v-card class="info_dialog">
      <v-card-text v-html="infoDialog.message"></v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="closeInfoDialog">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import { vMaska } from 'maska'
import errorMessages from '../Form/errorMessages'

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  directives: { maska: vMaska },
  data () {
    return {
      feedback: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      phoneEntered: false,
      infoDialog: {
        visible: false,
        message: ''
      },
      documents: [
        {
          title: 'Политика конфиденциальности',
          file: 'Политика_конфиденциальности_АО_Пуринвест.pdf'
        },
        {
          title: 'Лист ЕГРЮЛ',
          file: 'Лист_ЕГРЮЛ_АО_Пуринвест.pdf'
        },
        {
          title: 'Пользовательское соглашение',
          file: 'Пользовательское_соглашение_АО_Пуринвест.pdf'
        },
      ]
    }
  },
  validations () {
    return {
      feedback: {
          name: {
            required: helpers
                        .withMessage(
                          errorMessages.required.replace(':field', 'Имя'), 
                          required
                        )
          },
          email: {
            required: helpers
                        .withMessage(
                          errorMessages.required.replace(':field', 'E-mail'), 
                          required
                        ),
            email: helpers.withMessage(errorMessages.email, email)
          },
          phone: {
            required: helpers
                        .withMessage(
                          errorMessages.required.replace(':field', 'Телефон'), 
                          required
                        ),
            isMaskaValid: helpers
                            .withMessage(
                              errorMessages.phone,
                              () => this.phoneEntered
                            )
          },
          message: {
            required: helpers
                        .withMessage(
                          errorMessages.required.replace(':field', 'Сообщение'), 
                          required
                        )
          },
       }
    }
  },
  computed: {
    ...mapState('info', ['validationErrors']),
  },
  methods: {
    ...mapActions('info', ['sendFeedback']),
    ...mapMutations('info', ['setErrors']),
    onMaska (event) {
      this.phoneEntered = event.detail.completed
    },
    async onSubmit () {
      this.v$.$validate()
      if (!this.v$.$error) {
        let feedbackSent = await this.sendFeedback(this.feedback)
        if (!feedbackSent) {
          alert(this.apiError)
        }
        else {          
          // this.infoDialog.message = `Ваше обращение принято!`
          // this.infoDialog.visible = true
          alert(`Ваше обращение принято!`)
          this.feedback = {
            name: '',
            email: '',
            phone: '',
            message: '',
          }
        }
      }
      else {
        this.getErrorMessages()
      }
    },
    getErrorMessages () {
      let errors = {}
      if (this.v$.$errors.length != 0) {
        for (let error of this.v$.$errors) {
          errors[error.$property] = error.$message
        }
        this.setErrors(errors)
      }
    },
    closeInfoDialog () {
      this.infoDialog.visible = false
      this.infoDialog.message = ''
    }
  }  
}
</script>