export const SITE_NAME = 'Purinvest'

// export const API_URL = 'http://127.0.0.1:8000/api'
export const API_URL = 'https://vmi1571195.contaboserver.net/api'

// export const STORAGE_URL = 'http://127.0.0.1:8000/storage'
export const STORAGE_URL = 'https://vmi1571195.contaboserver.net/storage'

// export const WEBSOCKET_URL = 'http://localhost:3000'
export const WEBSOCKET_URL = 'https://vmi1571195.contaboserver.net:3000'
