<template>
	<form action="">
		<div class="form_section d-flex align-items-end">
			<div v-for="(field, index) of fields" :key="index" class="form_field_wrap relative">
				<div v-if="validationErrors[field.param]" class="form_field_error absolute">
					{{ validationErrors[field.param] }}
				</div>
				<input
					v-if="field.param == 'phone'"		
					v-model="v$.form[field.param].$model"
					:type="field.type"
					:name="field.param"
					:placeholder="field.placeholder"
					autocomplete
					class="form_field"
					v-maska
					data-maska="['+7 (###) ###-##-##']"
					@maska="onMaska"
				/>
				<input
					v-else				
					v-model="v$.form[field.param].$model"
					:type="field.type"
					:name="field.param"
					:placeholder="field.placeholder"
					autocomplete
					class="form_field"
				/>
			</div>
			<button type="button" class="form_button" @click="onSubmit">Зарегистрироваться</button>
		</div>
	</form>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, sameAs, helpers } from '@vuelidate/validators'
import { vMaska } from 'maska'
import errorMessages from './errorMessages'

export default {
	setup () {
		return { v$: useVuelidate() }
	},
	directives: { maska: vMaska },
	data () {
		return {
			form: {
				last_name: '',
				first_name: '',
				patronymic_name: '',
				birthday: null,
				phone: '',
				email: '',
				password: '',
				// password_confirmation: ''
			},
			fields: [
				{ param: 'last_name', type: 'text', placeholder: 'Фамилия' },
				{ param: 'first_name', type: 'text', placeholder: 'Имя' },
				{ param: 'patronymic_name', type: 'text', placeholder: 'Отчество' },
				{ param: 'birthday', type: 'date', placeholder: 'Дата рождения' },
				{ param: 'phone', type: 'text', placeholder: 'Телефон' },
				{ param: 'email', type: 'email', placeholder: 'E-mail' },
				{ param: 'password', type: 'password', placeholder: 'Пароль' },
				// { param: 'password_confirmation', type: 'password', placeholder: 'Повторите пароль' },
			],
			phoneEntered: false
		}
	},
	validations () {
		return {
			form: {
				last_name: {
					required: helpers
											.withMessage(
												errorMessages.required.replace(':field', 'Фамилия'), 
												required
											)
				},
				first_name: {
					required: helpers
											.withMessage(
												errorMessages.required.replace(':field', 'Имя'), 
												required
											)
				},
				patronymic_name: {
					required: helpers
											.withMessage(
												errorMessages.required.replace(':field', 'Отчество'), 
												required
											)
				},
				birthday: {
					isYearValid: helpers
												.withMessage(
													errorMessages.date,
													() => this.isYearValid
												)
				},
				phone: {
					required: helpers
											.withMessage(
												errorMessages.required.replace(':field', 'Номер телефона'), 
												required
											),
          isMaskaValid: helpers
          								.withMessage(
								            errorMessages.phone,
								            () => this.phoneEntered
								          )
				},
				email: {
					required: helpers
											.withMessage(
												errorMessages.required.replace(':field', 'E-mail'), 
												required
											),
					email: helpers.withMessage(errorMessages.email, email)
				},
				password: {
					required: helpers
											.withMessage(
												errorMessages.required.replace(':field', 'Пароль'), 
												required
											),
					minLength: helpers
											.withMessage(
												errorMessages.minLength
																		 .replace(':field', 'Пароль')
																		 .replace(':length', '8'), minLength(8)
											)
				},
				/*password_confirmation: {
					required: helpers
											.withMessage(
												errorMessages.required.replace(':field', 'Повтор пароля'), 
												required
											), 
					minLength: helpers
											.withMessage(
												errorMessages.minLength
																		 .replace(':field', 'Повтор пароля')
																		 .replace(':length', '8'), minLength(8)
											),
					sameAsPassword: helpers
														.withMessage(
            									errorMessages.passwordsNotCompare, 
            									// sameAs('password')
            									() => this.form.password == this.form.password_confirmation
            								)
				}*/
			}
		}
	},
	computed: {
		...mapState('auth', ['apiError', 'validationErrors']),
		isYearValid () {
			if (this.form.birthday) {
				let year = Number(this.form.birthday.split('-')[0])
				return year >= 1900 && year <= new Date().getFullYear()
			}
			return true
		}
	},
	methods: {
		...mapActions('auth', ['registerUser']),
		...mapMutations('auth', ['setErrors']),
    onMaska (event) {
			this.phoneEntered = event.detail.completed
    },
		async onSubmit () {
			this.v$.$validate()
			if (!this.v$.$error) {
				let registered = await this.registerUser(this.form)
				let resultEvent = registered ? 'success' : 'error'
				this.$emit(resultEvent)
			}
			else {
				this.getErrorMessages()
			}
		},
		getErrorMessages () {
			let errors = {}
			if (this.v$.$errors.length != 0) {
				for (let error of this.v$.$errors) {
					errors[error.$property] = error.$message
				}
				this.setErrors(errors)
			}
		},
	}
}
</script>
