<template>
  <div class="about_section layout_padding" id="faq">
		<div class="container">
		  <div class="row">
		     <div class="col-md-12">
		        <h1 class="about_taital">Частые вопросы <span class="border_0"></span></h1>
		        <!-- <p class="about_text">It is a long established fact that a reader will be distracted by the readable content of a page when</p> -->
		     </div>
		  </div>
		     <div class="row">
		        <div
		        	v-for="(item, index) of items"
		        	:key="index"
		        	class="col-md-12"
		        >
		           <p class="selection_text d-flex align-items-center" @click="item.open = !item.open">
			        	<img
			        		:src="require('@/assets/images/chevron-down.png')"
			        		class="selection_text_icon"
			        		:class="{ 'selection_text_icon_reverted': item.open }"
			        	>
		           	{{ item.question }}
		           </p>
		           <p v-show="item.open" class="many_text" v-html="item.answer"></p>
		        </div>
		     </div>
		  <div class="row">
		     <div class="col-md-12">
           <p class="about_text_1">
           	Наша команда состоит из опытных профессионалов, готовых помочь вам в достижении ваших финансовых целей.<br>
           	Мы стремимся предоставлять нашим клиентам высококлассное обслуживание и индивидуальный подход к каждому инвестору.
           </p>
		     </div>
		  </div>
		</div>
	</div>
	
</template>

<script>
export default {
	data () {
		return {
			items: [
				{
					question: 'Зачем мне брокер, если я хочу торговать ценными бумагами?',
					answer: `
						Первая и самая важная функция брокера — это доступ на биржу. Брокер берет на себя все обязательства по подключению своих клиентов к бирже и дальнейшему взаимодействию с ней.<br>
						Брокерская деятельность является лицензируемой и регулируемой. Защищает права инвесторов Центральный Банк Российской Федерации. Он контролирует работу всех брокеров. Центральный Банк выдает лицензию или забирает ее, если брокер нарушил правила.<br>
						Центральный Банк также следит за работой Бирж.<br>
						На брокерском счете учитываются деньги клиента и его позиции – что он купил или продал, и в каком объеме.
					`,
					open: false
				},
				{
					question: 'Что такое брокерский счет?',
					answer: `
						Брокерский счет дает возможность торговать на бирже – приобретать акции российских и иностранных компаний, облигации, еврооблигации, валюту по биржевому курсу и другие активы.<br>
						Также существует брокерский счет с особым льготным режимом – индивидуальный инвестиционный счет (ИИС). Ознакомьтесь с его преимуществами и ограничениями.<br>
						По налоговому вычету типа А возможно вернуть до 52 000 руб. НДФЛ в год. По вычету типа Б – освободить большую часть дохода от операций на бирже от удержания НДФЛ. Это может существенно улучшить итоговый финансовый результат для инвестора.
					`,
					open: false
				},
				{
					question: 'К каким торговым площадкам я получу доступ?',
					answer: `
						При заключении договора на брокерское обслуживание клиент отмечает рынки, на которых планирует торговать. Мы предоставляем доступ к Московской Бирже и СПБ Бирже.<br>
						С брокерским счетом в АО «Пуринвест» вам доступны:
						<ul>
							<li>Фондовый рынок Московской Биржи: акции, облигации, еврооблигации, ETF / БПИФ</li>
							<li>Валютный рынок Московской Биржи: валютные пары USD/RUB, EUR/RUB, GBP/RUB, CHF/RUB</li>
							<li>Срочный рынок Московской Биржи: опционы (длинные позиции) и фьючерсы</li>
							<li>Внебиржевой рынок: ценные бумаги иностранных эмитентов, включая еврооблигации (от $200 000)</li>
						</ul>
					`,
					open: false
				},
				{
					question: 'Как получать и выводить дивиденды?',
					answer: `
						Дивиденды – доход, который компании могут выплачивать держателям их акций, акционерам. Сумма и периодичность выплат зависят от дивидендной политики. Это не гарантированный доход. Для получения дивидендов необходимо приобрести акции компании, предварительно изучив историю ее дивидендных выплат, дивидендную политику и другие особенности.<br>
						Клиенты брокерского обслуживания АО «Пуринвест» могут приобретать акции онлайн через торговые терминалы или по телефону «с голоса». Дивиденды акций российских компаний поступают на текущий счет в Банке уже за вычетом налогов.
						<ul>
							<li>Согласно п. 1 ст. 8.7 ФЗ "О рынке ценных бумаг" лица, которые осуществляют права по акциям и облигациям (ценным бумагам), и права которых на такие ценные бумаги учитываются депозитарием, получают дивиденды в денежной форме по акциям или доходы в денежной форме и иные денежные выплаты по облигациям через депозитарий, депонентами которого они являются. Депозитарный договор должен содержать порядок передачи депозитарием депоненту выплат по ценным бумагам. При этом депозитарий обязан передавать выплаты по ценным бумагам путем перечисления денежных средств на банковские счета в соответствии с депозитарным договором.</li>
							<li>В соответствии с п 2.1.5. Регламента оказания брокерских услуг АО «Пуринвест» № 15-Р "Банк не принимает на обслуживание физических лиц в случае отказа Клиента от открытия Счета депо, Торгового счета депо в Банке и отсутствия Банковского счета в Банке. Физическим лицам до принятия на брокерское и депозитарное обслуживание необходимо открыть в Банке Банковский счет Клиента. Выплата доходов по ценным бумагам может осуществляться только с использованием Банковского счета в Банке, указанного в Анкете Клиента." Приложение 3.2 к Регламенту оказания брокерских услуг АО «Пуринвест» № 15-Р - 708-ОД-1-3.2</li>
							<li>В соответствии с п. 6 ст. 42 ФЗ "Об акционерных обществах" "Срок выплаты дивидендов номинальному держателю и являющемуся профессиональным участником рынка ценных бумаг доверительному управляющему, которые зарегистрированы в реестре акционеров, не должен превышать 10 рабочих дней, а другим зарегистрированным в реестре акционеров лицам - 25 рабочих дней с даты, на которую определяются лица, имеющие право на получение дивидендов.</li>
							<li>Выплаты по ценным бумагам клиентам (депонентам) передаются Депозитарием не позднее 7 рабочих дней после дня их получения Депозитарием. (п. 4 ст. 8.7 ФЗ "О рынке ценных бумаг").</li>
						</ul>
					`,
					open: false
				},
				{
					question: 'Что такое брокерский отчет?',
					answer: `
						Брокерский отчет — это документ, который подтверждает, какими бумагами владеет частный инвестор, и сколько денег находится у него на счете.<br>
						Все торговые операции, которые проходят по счету, брокер должен отражать в брокерском отчете.
					`,
					open: false
				},
				{
					question: 'Какие есть брокерские счета?',
					answer: `
						Существует два вида брокерских счетов:
						<ul>
							<li>Обычный брокерский счет. У одного физического лица их может быть несколько и у разных брокеров.</li>
							<li>Индивидуальный инвестиционный счет - брокерский счет с особым льготным режимом. Он может быть у физического лица только один. Но его можно перевести от одного брокера к другому с сохранением срока действия, что важно для получения налоговых льгот.</li>
						</ul>
						Можно одновременно открывать и иметь оба вида счетов: обычный брокерский счет и ИИС.
					`,
					open: false
				},
				{
					question: 'Как я буду получать отчетность по своему брокерскому счету?',
					answer: `
						В регламенте на брокерское обслуживание брокер указывает, как часто он будет присылать брокерский отчет инвестору. АО «Пуринвест» присылает Вам отчет на почту в том случае, если по счету прошла сделка, или произошло какое-либо другое движение. Отчет приходит на электронную почту, которую вы указали при приеме на брокерское обслуживание.
					`,
					open: false
				},
				{
					question: 'Что будет с моим счетом, если брокер обанкротится?',
					answer: `
						В случае банкротства брокера или отзыва у него лицензии, с ценными бумагами, приобретенными инвестором на бирже, ничего не случится. Учет ведется в Национальном расчетном депозитарии, который работает в соответствии с Федеральным законом от 07.12.2011 № 414-ФЗ «О центральном депозитарии». Бумаги будут переведены на обслуживание к другому брокеру.<br>
						Однако, помните, что инвестиции на бирже не застрахованы в соответствии с Федеральным законом «О страховании вкладов в банках Российской Федерации» от 23.12.2003 N177-ФЗ.
					`,
					open: false
				},
				{
					question: 'Какой размер комиссии на Фондовом рынке «Московской Биржи»?',
					answer: `
						Комиссия Московской Биржи также взимается от суммы сделки и составляет:
						<ul>
							<li>по акциям – 0,01%;</li>
							<li>по корпоративным облигациям – 0,0125%;</li>
							<li>по облигациям федерального займа (ОФЗ) – 0,01%.</li>
						</ul>
					`,
					open: false
				},
				{
					question: 'Когда с меня спишут комиссии за сделку?',
					answer: `
						Комиссия брокера и биржи будет списана, как только вы совершите сделку.
					`,
					open: false
				},
				{
					question: 'Как происходит авторизация карты и для чего она нужна?',
					answer: `
						Авторизация банковской карты в брокерской системе является важным шагом для обеспечения безопасности ваших финансовых операций. Вот несколько причин, почему это необходимо:
						<ol>
							<li>Подтверждение владения картой: Авторизация карты позволяет убедиться, что вы являетесь законным владельцем карты, которая будет использоваться для вывода средств с торгового счета. Это помогает предотвратить мошенничество и защищает вас от неправомерного доступа к вашим финансам.</li>
							<li>Безопасность финансовых операций: Авторизация карты обеспечивает дополнительный уровень защиты при осуществлении финансовых операций.Это позволяет брокерской системе проверить подлинность вашей карты и подтвердить, что средства будут переведены на правильный счет.</li>
							<li>Соответствие правилам и требованиям: Многие брокерские системы подчиняются правилам и требованиям финансовых регуляторов. Подтверждение карты может быть одним из этих требований, чтобы убедиться, что вы соответствуете всем правилам и политикам, предусмотренным для защиты интересов всех сторон.</li>
							<li>Упрощение процесса вывода средств: После успешной авторизации вашей банковской карты, путем пополнения торгового счета на минимальную сумму в компании процесс вывода средств может быть более быстрым и удобным. Вам может потребоваться ввести определенные данные карты только один раз, и в дальнейшем вы сможете легко и быстро осуществлять вывод своих средств без необходимости повторного ввода информации.</li>
						</ol>
					`,
					open: false
				},
			]
		}
	}
}
</script>
