<template>
  <AboutSection />
  <CustomSection
    v-for="(section, index) of sections"
    :key="index"
    :section="section"
  />
  <AnalyticsSection />
  <InvestmentSection />
  <FaqSection />
  <ContactSection />
</template>

<script>
import AboutSection from '@/components/Section/AboutSection'
import CustomSection from '@/components/CustomSection'
import AnalyticsSection from '@/components/Section/AnalyticsSection'
import InvestmentSection from '@/components/Section/InvestmentSection'
import FaqSection from '@/components/Section/FaqSection'
import ContactSection from '@/components/Section/ContactSection'

export default {
  name: 'HomeView',
  components: {
    AboutSection,
    CustomSection,
    AnalyticsSection,
    InvestmentSection,
    FaqSection,
    ContactSection
  },
  data () {
    return {
      sections: [
        {
          name: 'services',
          backgroundType: 'url',
          backgroundUrl: require('@/assets/images/section-2.jpg'),
          text: 'Мы уделяем особое внимание безопасности и надежности, поэтому все наши операции осуществляются в соответствии с требованиями регуляторов.',
          text2: 'Мы предлагаем широкий спектр услуг, включая брокерское обслуживание, управление активами, консультации по инвестированию и многое другое.',
          textColor: 'white'
        },
        {
          name: 'experience',
          backgroundType: 'color',
          backgroundColor: 'rgba(12, 15, 56, .9)',
          text: 'АО &laquo;Пуринвест&raquo; является ведущей компанией с 19-летним опытом в области инвестиций и торговли ценными бумагами.<br>Наша команда предоставляет безопасный доступ к финансовому и фондовому рынку для корпоративных и частных инвесторов.',
          textColor: 'white'
        },
        {
          name: 'clients',
          backgroundType: 'url',
          backgroundUrl: require('@/assets/images/section-4.jpg'),
          text: 'АО &laquo;Пуринвест&raquo; - это 6000 активных клиентов, которые нам доверяют.',
          textColor: 'white'
        },
      ]
    }
  }
}
</script>
