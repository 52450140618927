<template>
  <div
  	v-if="section"
  	class="custom_section"
  	:class="`custom_section_${section.name} custom_section_${section.backgroundType}`"
  	:style="getSectionStyle(section)"
  >
		<div class="container">
		  <!-- <div class="about_section_2"> -->
		     <div class="row align-items-center">
		        <!-- <div class="col-md-6">
		          <div class="about_img">
		           	<img :src="require('@/assets/images/purinvest-about.jpg')">
		          </div>
		        </div> -->
		        <!-- <div class="col-md-6"> -->
		        <div class="col-md-12">
		           <!-- <p class="about_text_1">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words </p> -->
		          <div class="column_section_wrap">
		          <p
		           	class="custom_section_text"
		           	:style="{ 'color': section.textColor }"
		           	v-html="section.text"
		          ></p>
		          <p
		          	v-if="section.text2"
		           	class="custom_section_text custom_section_text_secondary"
		           	:style="{ 'color': section.textColor }"
		           	v-html="section.text2"
		          ></p>
		        </div>
		          <!-- <div class="readmore_bt"><a href="#">Read More</a></div> -->
		        </div>
		     </div>
		  <!-- </div> -->
		</div>
  </div>
</template>

<script>
export default {
	props: {
		section: {
			type: Object,
			default: null
		}
	},
  methods: {
    getSectionStyle(section) {
      if (section.backgroundType === 'url') {
        return { 'background-image': `url(${section.backgroundUrl})` }
      } else if (section.backgroundType === 'color') {
        return { 'background-color': section.backgroundColor }
      } else {
        return {}
      }
    }
  }
}
</script>
