<template>
	<div v-show="dialog.visible" class="dialog_shadow">
		<div class="dialog_wrap">
			<div class="dialog_window">		
				<div class="container">
				  <div class="row">
				     <div class="col-md-12 p-0">
				        <h1 class="dialog_taital d-flex justify-content-between align-items-center">
				        	{{ dialogTitle }}
				        	<img
				        		:src="require('@/assets/images/close.png')"
				        		@click="closeAuthDialog"
				        	>
				        </h1>
				     </div>
				  </div>
				  <div class="row">
				    <div class="col-md-12 p-0">	
							<LoginForm v-if="dialog.action == 'login'" @success="onSuccess" @error="onError" />
							<RegisterForm v-if="dialog.action == 'register'" @success="onSuccess" @error="onError" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import LoginForm from '@/components/Form/LoginForm'
import RegisterForm from '@/components/Form/RegisterForm'

export default {
	props: {
		dialog: {
			type: Object,
			default: () => {
				return {
					visible: false,
					action: null
				}
			}
		}
	},
	components: { LoginForm, RegisterForm },
	computed: {
		...mapState('auth', ['apiError', 'validationErrors']),
		dialogTitle () {
			if (this.dialog.action == 'login') return 'Войти'
			if (this.dialog.action == 'register') return 'Регистрация'
			return ''
		}
	},
	methods: {
		closeAuthDialog () {
  		this.dialog.action = null
  		this.dialog.visible = false
		},
		onSuccess () {
			this.closeAuthDialog()
			this.$router.push({ name: 'profile' })
		},
		onError () {
			alert(this.apiError)
		}
	}
}
</script>
