<template>
	<form action="">
		<div class="form_section d-flex align-items-end">
			<div v-for="(field, index) of fields" :key="index" class="form_field_wrap relative">
				<div v-if="validationErrors[field.param]" class="form_field_error absolute">
					{{ validationErrors[field.param] }}
				</div>
				<input					
					v-model="v$.form[field.param].$model"
					:type="field.type"
					:name="field.param"
					:placeholder="field.placeholder"
					class="form_field"
				/>
			</div>
			<button type="button" class="form_button" @click="onSubmit">Войти</button>
		</div>
	</form>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators'
import errorMessages from './errorMessages'

export default {
	setup () {
		return { v$: useVuelidate() }
	},
	data () {
		return {
			form: {
				email: '',
				password: ''
			},
			fields: [
				{ param: 'email', type: 'email', placeholder: 'E-mail' },
				{ param: 'password', type: 'password', placeholder: 'Пароль' },
			]
		}
	},
	validations () {
		return {
			form: {
				email: {
					required: helpers
											.withMessage(
												errorMessages.required.replace(':field', 'E-mail'), 
												required
											),
					email: helpers.withMessage(errorMessages.email, email)
				},
				password: {
					required: helpers
											.withMessage(
												errorMessages.required.replace(':field', 'Пароль'), 
												required
											),
					minLength: helpers
											.withMessage(
												errorMessages.minLength
																		 .replace(':field', 'Пароль')
																		 .replace(':length', '8'), minLength(8)
											)
				}
			}
		}
	},
	computed: {
		...mapState('auth', ['apiError', 'validationErrors'])
	},
	methods: {
		...mapActions('auth', ['loginUser']),
		...mapMutations('auth', ['setErrors']),
		async onSubmit () {
			this.v$.$validate()
			if (!this.v$.$error) {
				let loggedIn = await this.loginUser(this.form)
				let resultEvent = loggedIn ? 'success' : 'error'
				this.$emit(resultEvent)
			}
			else {
				this.getErrorMessages()
			}
		},
		getErrorMessages () {
			let errors = {}
			if (this.v$.$errors.length != 0) {
				for (let error of this.v$.$errors) {
					errors[error.$property] = error.$message
				}
				this.setErrors(errors)
			}
		},
	}
}
</script>
