<template>
  <div class="about_section layout_padding" id="about">
		<div class="container">
		  <div class="row">
		     <div class="col-md-12">
		        <h1 class="about_taital">О нас <span class="border_0"></span></h1>
		        <!-- <p class="about_text">It is a long established fact that a reader will be distracted by the readable content of a page when</p> -->
		     </div>
		  </div>
		  <!-- <div class="about_section_2"> -->
		     <div class="row">
		        <!-- <div class="col-md-6">
		          <div class="about_img">
		           	<img :src="require('@/assets/images/purinvest-about.jpg')">
		          </div>
		        </div> -->
		        <!-- <div class="col-md-6"> -->
		        <div class="col-md-12">
		           <!-- <p class="about_text_1">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words </p> -->
		           <p class="about_text_1">
		           	Компания, основанная в 2002 году, гордится своей прочной репутацией и устоявшимся статусом действующего партнера Центрального Банка Российской Федерации.<br>
		           	Это подтверждает нашу квалификацию и способность предоставлять высококачественные финансовые услуги нашим клиентам.
		           </p>
		           <!-- <div class="readmore_bt"><a href="#">Read More</a></div> -->
		        </div>
		     </div>
		  <!-- </div> -->
		</div>
  </div>
</template>
