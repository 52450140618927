<template>
      <!-- footer section start -->
      <!-- <div class="footer_section layout_padding"> -->
         <!-- <div class="container"> -->
            <!-- <div class="location_main">
               <div class="location_text"><a href="#"><span class="padding_15"><i class="fa fa-map-marker" aria-hidden="true"></i></span> Location</a></div>
               <div class="location_text"><a href="#"><span class="padding_15"><i class="fa fa-phone" aria-hidden="true"></i></span> Call +01 1234567890</a></div>
               <div class="location_text"><a href="#"><span class="padding_15"><i class="fa fa-envelope" aria-hidden="true"></i></span>demo@gmail.com</a></div>
            </div> -->
            <!-- <div class="footer_section_2"> -->
               <!-- <div class="row">
                  <div class="col-md-6">
                     <p class="lorem_text">
                        Брокер осуществляет деятельность на основании лицензии профессионального участника рынка ценных бумаг на осуществление брокерской деятельности<br>
                        ИНН 8911000962;<br>
                        КПП 772201001;<br>
                        ОГРН 1028900857490;<br>
                        Дата присвоения Центральным Банком РФ: 28.08.2002. (Без ограничения
                        срока действия)
                     </p>
                  </div>
                  <div class="col-md-6">
                     <p class="lorem_text">
                     	<a>Политика конфиденциальности</a><br>
                     	<a>Лист ЕГРЮЛ</a><br>
                     	<a>Пользовательское соглашение</a><br>
                     </p>
                  </div>
                  <div class="col-md-6">
                     <p class="lorem_text">
                        НАЙТИ НАС<br>
                        111020, г.Москва, ул.Боровая, д.3 стр.22
                     </p>
                  </div>
               </div> -->
            <!-- </div> -->
         <!-- </div> -->
      <!-- </div> -->
      <!-- footer section end -->
      <!-- copyright section start -->
      <div class="copyright_section">
         <div class="container">
            <div class="row">
               <div class="col-sm-12">
                  <!-- <p class="copyright_text">{{ new Date().getFullYear() }} All Rights Reserved. Design by <a href="https://html.design" rel="nofollow">HTML.DESIGN</a></p> -->
                  <p class="copyright_text">© 2002-{{ new Date().getFullYear() }} Пуровская инвестиционная компания</p>
               </div>
            </div>
         </div>
      </div>
</template>