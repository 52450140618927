import api from '@/services/api'

export const namespaced = true

export const state = {
  analytics: [],
  apiError: null,
  validationErrors: {},
}

export const mutations = {
  setAnalytics(state, analytics) {
    state.analytics = analytics
  },
  setError(state, error) {
    state.apiError = error
  },
  setErrors(state, errors) {
    state.validationErrors = errors
  },
}

export const actions = {
  async getAnalytics ({ commit }) {
    commit('setError', null)
    commit('setErrors', {})
    try {
      const response = await api.get('/info/analytics')
      // console.log(response.data)
      if (response.data.success) {
        commit('setAnalytics', response.data.analytics)
      }
      else {
        console.log(response.data.error)
        commit('setError', response.data.error)
      }
    } catch (error) {
      console.error(error)
      commit('setError', 'Ошибка получения данных. Попробуйте позже')
    }
  },
  async sendFeedback ({ commit }, payload) {
    commit('setError', null)
    commit('setErrors', {})
    try {
      const response = await api.post('/info/send-feedback', payload)
        .catch((error) => {
          console.log(error)
          commit('setError', 'Ошибка отправки сообщения. Попробуйте позже')
        })
      // console.log(response.data)
      if (response.data.success) {
        return true
      }
      else {
        console.log(response.data.error)
        commit('setError', response.data.error)
        return false
      }
    } catch (error) {
      console.error(error)
      commit('setError', 'Ошибка отправки сообщения. Попробуйте позже')
      return false
    }
  },
}

export const getters = {

}
