import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

require('@/assets/css/bootstrap.min.css')
require('@/assets/css/style.css')
require('@/assets/css/responsive.css')
// require('@/assets/css/jquery.mCustomScrollbar.min.css')

import 'jquery'
import 'jquery-migrate'

require('@/assets/js/popper.min.js')
require('@/assets/js/bootstrap.bundle.min.js')
require('@/assets/js/plugin.js')

createApp(App)
	.use(store)
	.use(router)
	.use(vuetify)
	.mount('#app')
